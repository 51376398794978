<template>
  <aside class="aside">
    <div>
      <h3>Format</h3>
      <div class="format-icons">
        <div class="tooltip" v-for="i in lessonData.meta.format" :key="i">
          <img
            :src="require('@/assets/images/icon_format_' + i + '.png')"
            />
          <span class="tooltiptext">{{ capitalize(i) }}</span>
        </div>
      </div>
    </div>
    <div>
      <h3>Prerequisites</h3>
      <div v-html="lessonData.meta.prerequisites"></div>
    </div>
    <div>
      <h3>Tier</h3>
      <div v-html="lessonData.meta.tier.expandedText"></div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "IndexAside",
  props: {
    lessonData: Object,
  },
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>

<style scoped lang="sass">
@import '../assets/styles/e_layout/_media.sass'

.tooltip
  all: unset
  position: relative
  display: inline-block

.tooltip .tooltiptext
  visibility: hidden
  width: min-content
  background-color: black
  color: #fff
  text-align: center
  border-radius: 6px
  padding: 5px var(--space-xs)

  /* Position the tooltip */
  position: absolute
  z-index: 1
  bottom: 110%
  left: 100%
  margin-left: -60px

.tooltip:hover .tooltiptext
  visibility: visible

.index-content
  aside > div
    margin-bottom: var(--space-sm)

.format-icons
  display: flex
  grid-gap: var(--space-2xs)
  margin-top: var(--space-xs)

</style>