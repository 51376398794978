<template>
  <div id="user-page-container">
    <WrongPlanAlert v-if="tierFailure !== ''" :tier="tierFailure" />
    <CheckoutAlert
      v-if="checkoutPromptTier !== ''"
      :tier="checkoutPromptTier"
    />
    <FinishedCheckoutAlert
      @alertDismissed="delaySecondsForReload=0; refreshCurrentPage()"
      @countDownChanged="delaySecondsForReload=$event; remainingSeconds=$event"
      :delaySeconds="delaySecondsForReload"
      :remainingSeconds="remainingSeconds"
    />
    <ProfileDetails
      :userPicture="$auth.user.picture"
      :userEmail="$auth.user.email"
      :userName="$auth.user.name"
      :userIsSubscriber="userIsSubscriber"
      @openChargebeePortal="openPortal"
      @openChargebeeCheckout="openCheckout"
    />
  </div>
</template>

<script>
import WrongPlanAlert from "../components/profile/WrongPlanAlert.vue";
import CheckoutAlert from "../components/profile/CheckoutAlert.vue";
import FinishedCheckoutAlert from "../components/profile/FinishedCheckoutAlert.vue";
import ProfileDetails from "../components/profile/ProfileDetails.vue";

import axios from "axios";
import jwt_decode from "jwt-decode";

const moonriseApiBaseUrl = process.env.VUE_APP_API_BASE_ENDPOINT;
const axiosClient = axios.create({ baseURL: moonriseApiBaseUrl });

export default {
  name: "ProfileView",
  components: {
    WrongPlanAlert,
    CheckoutAlert,
    FinishedCheckoutAlert,
    ProfileDetails,
  },
  data() {
    return {
      tokenData: {},
      userIsSubscriber: false,
      tierFailure: "",
      checkoutPromptTier: "",
      finishedCheckoutStatus: "",
      remainingSeconds: 0,
    };
  },
  async mounted() {
    if (this.$route.query.tierFailure) {
      this.tierFailure = this.$route.query.tierFailure;
    }
    if (this.$route.query.checkoutPromptTier) {
      this.checkoutPromptTier = this.$route.query.checkoutPromptTier;
    }
    if (this.$route.query.finishedCheckoutStatus) {
      this.finishedCheckoutStatus = this.$route.query.finishedCheckoutStatus;
      this.remainingSeconds = 10;
    }
    const token = await this.getToken();
    this.setUserAsSubscriberIfTokenHasCorrectPermissions(token);
    this.cbInstance = window.Chargebee.init({
      site: process.env.VUE_APP_CHARGEBEE_SITE,
    });
    this.cbInstance.setPortalSession(() => {
      return axiosClient
        .get("/chargebee-portal-session", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data);
    });
  },
  methods: {
    refreshCurrentPage() {
      window.location = window.location.pathname + window.location.hash;
    },
    setUserAsSubscriberIfTokenHasCorrectPermissions(token) {
      var decoded = jwt_decode(token);
      this.tokenData = decoded;
      var permissions = decoded["permissions"];
      if (permissions.includes("access:standard")) {
        this.userIsSubscriber = true;
      }
    },
    async getToken() {
      const token = await this.$auth.getTokenSilently();
      return token;
    },
    openPortal() {
      this.cbInstance.createChargebeePortal().open({
        loaded: () => {},
        close: () => {
          //  eslint-disable-next-line no-console
          console.log("window closed");
        },
        paymentSourceAdd: (status) => {
          //  eslint-disable-next-line no-console
          console.log("payment source add" + status);
        },
        paymentSourceUpdate: (status) => {
          //  eslint-disable-next-line no-console
          console.log("payment source update" + status);
        },
        paymentSourceRemove: (status) => {
          //  eslint-disable-next-line no-console
          console.log("payment source removed" + status);
        },
      });
    },
    async openCheckout() {
      const token = await this.getToken();
      this.cbInstance.openCheckout({
        hostedPage: () => {
          return axiosClient
            .get("/chargebee-checkout-new", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => response.data)
            .catch((error) => {
              /* eslint-disable no-console */
              console.log(error);
              /* eslint-enable no-console */
              this.raiseCheckoutErrorNotification();
            });
        },
        loaded: function () {},
        close: function () {},
        success: function (hostedPageId) {
          //  eslint-disable-next-line no-console
          console.log("we should refresh the page here");
          // Seems to work, but we want to delay it 
          window.location.href = "/profile?finishedCheckoutStatus=success";
          //  eslint-disable-next-line no-console
          console.log(hostedPageId);
        },
        step: function (value) {
          // value -> which step in checkout
          //  eslint-disable-next-line no-console
          console.log(value);
        },
      });
    },
  },
};
</script>