<template>
  <div class="profile-details">
    <section id="profile-details-container">
      <h2 id="profile-details-title">Profile Details</h2>
      <div>
        <div><img class="user-picture" :src="userPicture" /></div>

        <div class="text-section">
          <p><span class="profile-detail">Username: </span></p>
          <p>{{ userName }}</p>
        </div>
        <div class="text-section">
          <p><span class="profile-detail">Email: </span></p>
          <p>{{ userEmail }}</p>
        </div>
      </div>
      <div class="plan card">
        <div class="card-body">
          <div class="text-section plan">
            <p>
              <span class="profile-detail">Current Plan: </span>
            </p>
            <p>{{ !userIsSubscriber ? "Free" : "Standard" }}</p>
          </div>
        </div>
        <div class="card-body">
          <PriceTier />
          <div v-if="userIsSubscriber">
            <b-button
              id="billing-details-button"
              variant=""
              @click="$emit('openChargebeePortal')"
              >See Billing Details</b-button
            >
          </div>
          <div v-else-if="!userIsSubscriber">
            <b-button
              id="upgrade-button"
              variant=""
              @click="$emit('openChargebeeCheckout')"
              >Upgrade Now</b-button
            >
          </div>
          <div v-if="couponCode">
            <b-button
              v-b-modal.refer-modal
              id="coupon-button"
              variant=""
              >Refer &amp; Save</b-button
            >
          </div>
        </div>
      </div>
      <div>
      <b-modal
        ok-only
        id="refer-modal"
        title="Refer &amp; Save">
        <p>
          This referral code will give anyone who uses it a 25%
          discount and you a one-time $5 credit!
        </p>
        <b-container fluid>
          <b-row>
            <b-col sm="6">
              <b-form-input
                v-on:focus="$event.target.select()"
                type="text"
                ref="clone"
                readonly
                :value="couponCode"
              >
                {{couponCode}}
              </b-form-input>
            </b-col>
            <b-col sm="6">
              <b-button
                class="copy-button"
                @click="copy"
                variant="primary"
              >
                Copy Code
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div>
    </section>
  </div>
</template>

<script>
import axios from "axios"
import PriceTier from "../profile/PriceTier.vue"

const moonriseApiBaseUrl = process.env.VUE_APP_API_BASE_ENDPOINT
const axiosNoRetryClient = axios.create({ baseURL: moonriseApiBaseUrl })

export default {
  name: "ProfileDetails",
  props: {
    userPicture: String,
    userName: String,
    userEmail: String,
    userIsSubscriber: Boolean,
  },
  data() {
    return {
      couponCode: "",
    }
  },
  methods: {
    async setUserCouponCode() {
      const token = await this.$auth.getTokenSilently()
      const couponCodeResponse = await axiosNoRetryClient.get("/user/coupon", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      this.couponCode = couponCodeResponse.data
    },
    copy() {
      navigator.clipboard.writeText(this.couponCode);
      this.makeToast();
    },
    makeToast() {
      this.$bvToast.toast('Code Copied!', {
        title: `Success!`,
        variant: "info",
        solid: true
      })
    }
  },
  async beforeMount() {
    await this.setUserCouponCode()
  },
  components: { PriceTier },
}
</script>

<style lang="sass" scoped>
@import '../../assets/styles/e_layout/_media'

#upgrade-button, #billing-details-button
  background-color: var(--color-a1)
  border: none
  color: var(--color-t)
  padding-left: var(--space-sm)
  padding-right: var(--space-sm)
  margin-top: var(--space-xs)
  margin-bottom: var(--space-sm)

#coupon-button
  background-color: var(--color-a1)
  border: none
  color: var(--color-t)
  padding-left: var(--space-sm)
  padding-right: var(--space-sm)

.card.plan
  border-color: var(--color-a2)
  max-width: 500px
  @include md
    grid-column: 2

.card-body
  display: grid
  place-items: center
  border-bottom: 1px solid var(--color-a2)

.profile-details
  max-width: var(--layout-max-width)
  margin: auto

section
  background-color: var(--color-b-alt)
  padding-top: var(--space-sm)
  padding-left: var(--space-sm)
  padding-right: var(--space-sm)
  padding-bottom: var(--space-md)
  margin-bottom: var(--space-sm)

  @include md
    margin-top: var(--space-md)
  @include lg
    padding-top: var(--space-md)
    padding-left: var(--space-md)
    padding-right: var(--space-md)
    padding-bottom: var(--space-md)

.user-picture
  padding: 0 var(--space-md)
  margin-bottom: var(--space-sm)
  max-width: 300px

  @include lg
    margin-bottom: var(--space-md)
.text-section
  margin-bottom: var(--space-sm)
  grid-column: 1
  *
    margin-bottom: 0

  &.plan
    place-self: stretch
    display: flex
    justify-content: space-between
    margin-bottom: 0
    padding-left: var(--space-sm)
    padding-right: var(--space-sm)

  @include lg
    display: grid
    grid-template-columns: minmax(100px,200px) auto

#profile-details-container
  #profile-details-title
    margin-bottom: var(--space-sm)

  @include md
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: var(--space-md)

  #profile-details-title
    grid-column: 1/-1

.account-settings
  min-height: 400px

::v-deep #refer-modal
  margin-top: 200px
</style>
