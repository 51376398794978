<template>
  <div class="nav-options" :class="type">
    <div v-if="$auth.isAuthenticated" style="display: contents">
      <b-nav-item to="/profile"> Profile </b-nav-item>
      <b-nav-item-dropdown
        id="my-nav-dropdown"
        text="Courses"
        toggle-class="nav-link-custom"
        right
      >
        <b-dropdown-item
          href="https://www.moonriselabs.com/courses"
          target="_blank"
          >All Courses</b-dropdown-item
        >
        <b-dropdown-divider />
        <b-dropdown-item href="/courses/python-fundamentals/"
          >Python Fundamentals</b-dropdown-item
        >
        <b-dropdown-item href="/courses/command-line-fundamentals/"
          >Command Line Fundamentals</b-dropdown-item
        >
        <b-dropdown-item href="/courses/python-in-the-cloud/"
          >Python in the Cloud: DynamoDB</b-dropdown-item
        >
        <!-- <b-dropdown-item href="/courses/python-in-the-cloud-sqs/"
          >Python in the Cloud: SQS</b-dropdown-item
        > -->
      </b-nav-item-dropdown>
      <b-nav-item to="/profile" @click.prevent="logout"> Log Out </b-nav-item>
    </div>
    <div v-else-if="!$auth.isAuthenticated && !$auth.loading">
      <b-dropdown-item class="rightItem" @click.prevent="login">
        Log In
        <font-awesome-icon class="ml-3 icon" icon="power-off" />
      </b-dropdown-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavOptions",
  props: {
    type: String,
  },
  methods: {
    profilePicture() {
      // eslint-disable-next-line no-console
      console.log(this.$auth.user.picture)
      if (this.$auth.user.picture) {
        return this.$auth.user.picture
      }
      // else return some default image?
      // none yet for reference
    },
    logout() {
      this.$auth.logout()
      this.$router.push({ path: "/" })
    },
    login() {
      this.$auth.loginWithRedirect()
    },
  },
}
</script>

<style scoped lang="sass">
.nav-options
  display: contents

.rightItem
  text-align: right

::v-deep

  @media only screen and (max-width: 600px)
    a.nav-link
      font-size: unset

  a
    text-decoration: none

.dark
  .icon *
    color: var(--color-t-dark)

  ::v-deep
    a
      color: var(--color-t-dark)
      .dropdown-item:hover
        background-color: var(--color-p2)
</style>
