<template>
  <b-alert
    :show="remainingSeconds"
    dismissible
    variant="warning"
    @dismissed="$emit('alertDismissed')"
    @dismiss-count-down="countDownChanged"
  >
    <p>Success! We will reload your account shortly to load the new features!</p>
    <b-progress
      variant="warning"
      :max="alertLifespanSeconds"
      :value="remainingSeconds"
      height="4px"
    ></b-progress>
  </b-alert>
</template>

<script>
export default {
  name: "FinishedCheckoutAlert",
  data () {
    return {
      alertLifespanSeconds: 10
    }
  },
  methods: {
    countDownChanged(newRemainingSeconds) {
      this.$emit('countDownChanged', newRemainingSeconds)
    },
  },
  props: {
    remainingSeconds: Number
  },
};
</script>

<style scoped>
#checkoutAlert {
  z-index: 2000;
  width: 100vw;
  font-size: 1.5rem;
}
</style>
