<template>
  <div class="price-tier">
    <div class="">
      <div class="price">Standard: $20/month</div>

      <div class="features">
        <ul>
          <li>Expert-made software development courses</li>
          <li>Access to exclusive software engineering workshops</li>
          <li>Real-world development experience</li>
          <li>First access to new releases and features</li>
          <li>Cancel anytime</li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
};
</script>

<style lang="sass" scoped>
@import '../../assets/styles/c_typography/_text-sizes'

.price-tier
  padding-top: 0
  padding-bottom: 0
  display: grid
  place-content: center
  margin-left: 0
  margin-right: 0

  .price
    @include text(xl)
    text-align: center
    font-weight: 500
    margin-top: var(--space-xs)
    margin-bottom: var(--space-sm)

    .features
      @include text(sm)
      margin-bottom: var(--space-sm)

    li
      margin-bottom: var(--space-2xs)
      list-style: none

      &:before
        content: ""
        height: 1.5em
        width: 1.5em
        display: block
        float: left
        margin-left: -2em
        background-position: center
        background-repeat: no-repeat
        background-size: 100%
        background-image: url("~@/assets/images/check-mark-circle-filled.svg")
</style>
