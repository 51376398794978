<template>
  <div>
    <b-nav-item
      v-if="lesson.lessonSlug === 'index'"
      :to="{ name: 'index', params: { courseSlug: courseSlug } }"
      >{{ lesson.lessonTitle }}
    </b-nav-item>
    <b-nav-item
      v-if="lesson.lessonSlug !== 'index'"
      :to="{
        name: 'lessons',
        params: { courseSlug: courseSlug, lessonSlug: link },
      }"
      >{{lesson.lessonOrder}}. {{ lesson.lessonTitle }}
    </b-nav-item>
  </div>
</template>

<script>
export default {
  name: "CourseListItem",
  props: {
    lesson: Object,
    courseSlug: String,
  },
  computed: {
    link() {
      return this.lesson.lessonSlug;
    },
  },
};
</script> 
