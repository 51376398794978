<template>
  <b-col id="leftNav" class="d-none d-sm-block">
    <b-navbar>
      <ItemsList :allLessons="allLessons" :courseSlug="courseSlug" />
    </b-navbar>
  </b-col>
</template>

<script>
import ItemsList from "./ItemsList.vue"

export default {
  name: "RegularSidebar",
  components: {
    ItemsList,
  },
  props: {
    allLessons: Array,
    courseSlug: String,
  },
}
</script> 

<style scoped lang="sass">
@import '../../assets/styles/e_layout/_media.sass'

#leftNav
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-border-box
  box-sizing: border-border-box
  border-right: 1px solid #eaecef
  overflow-y: scroll
  position: sticky
  z-index: 1000

  ::v-deep // target classes inside the child components
    .activeItem
      padding: 0

    .nav-link
      text-decoration: none
      color: var(--color-t-alt)

      @include lg
        padding-left: var(--space-sm)

      &:hover
        color: var(--color-p1)
        text-decoration: underline

    .navbar
      padding-left: 0
      padding-right: 0
</style>
