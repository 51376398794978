<template>
  <div v-if="lessonData" class="index-content">
    <IndexHero :lessonData="lessonData" />
    <div class="body">
      <IndexPageSidebar :orderedLessons="orderedLessons" :courseSlug="courseSlug" />
      <IndexAside :lessonData="lessonData" />
      <span v-if="lessonData" class="description" v-html="lessonData.content">
      </span>
    </div>
  </div>
</template>

<script>
import IndexHero from "@/components/IndexHero.vue";
import IndexAside from "@/components/IndexAside.vue";
import IndexPageSidebar from "@/components/IndexPageSidebar.vue";

import { enrichManifest } from "./utils";

import markdownItHighlight from "markdown-it-highlightjs"
import markdownItAnchor from "markdown-it-anchor"
import markdownItMeta from "markdown-it-meta"

var md = require("markdown-it")()
  .use(require("markdown-it-external-links"), {
    externalClassName: null,
    externalRel: "noopener noreferrer",
    externalTarget: "_blank",
  })
  .use(require("markdown-it-task-lists"), {
    label: true,
  })
  .use(require("markdown-it-abbr"))
  .use(require("markdown-it-sup"))
  .use(require("markdown-it-sub"))
  .use(require("markdown-it-mark"))
  .use(require("markdown-it-ins"))
  .use(markdownItAnchor, {
    permalink: false,
    permalinkSymbol: "",
    permalinkBefore: true,
    permalinkSpace: true,
  })

md.use(markdownItHighlight, { auto: false })
md.use(markdownItMeta)

export default {
  name: "IndexPage",
  data: function () {
    return {
      lessonList: [],
    };
  },
  props: {
    courseSlug: String,
  },
  mounted() {
    this.lessonList = this.loadingLessonList;
  },
  components: {
    IndexHero,
    IndexAside,
    IndexPageSidebar,
  },
  created() {},
  computed: {
    loadingLessonList() {
      const manifest = enrichManifest(
        require(`../assets/code/${this.courseSlug}/manifest.json`)
      );
      return manifest;
    },
    orderedLessons() {
      return [...this.lessonList].sort((a, b) => a.lessonOrder - b.lessonOrder);
    },
    lessonData() {
      if (this.lessonList) {
        const indexLesson = this.lessonList.find(
          (obj) => obj.lessonSlug === "index"
        )

        if (indexLesson) {
          return {
            content: md.render(indexLesson.content),
            title: indexLesson.lessonTitle,
            meta: md.meta,
          }
        }
      }

      return ""
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
};
</script> 

<style scoped lang="sass">
@import '../assets/styles/e_layout/_media.sass'

.body
  padding-left: var(--space-sm)
  padding-right: var(--space-sm)
  margin-bottom: 4rem

  @include lg
    padding-left: var(--space-md)
    padding-right: var(--space-md)
    display: grid
    grid-template-columns: 2fr 8fr 2fr
    // grid-gap: var(--space-md)

    .sidebar
      grid-column: 1
      grid-row: 1
      margin-top: var(--space-sm)

    .aside
      grid-column: 3
      grid-row: 1
      margin-top: var(--space-sm)

    .description
      padding-left: var(--space-lg)
      padding-right: var(--space-lg)
      grid-column: 2
      grid-row: 1

      @include lg
        padding-left: var(--space-md)

      ::v-deep(h2)
        margin-top: var(--space-sm)
        margin-bottom: var(--space-sm)

</style>
