<template>
  <b-navbar
    toggleable="lg"
    :type="isDark ? 'dark' : 'light'"
    id="topNavBar"
    :class="{ dark: isDark }"
  >
    <NavLogo />

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <NavOptions :type="isDark ? 'dark' : 'light'" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import NavLogo from "./navigation/NavLogo.vue"
import NavOptions from "./navigation/NavOptions.vue"

export default {
  name: "NavBar",
  components: {
    NavLogo,
    NavOptions,
  },
  computed: {
    isDark() {
      // if (this.$route.name == "index") {
      //   return true
      // } else {
      //   return false
      // }
      return true
    },
  },
}
</script>

<style lang="sass" scoped>
#mobileAuthNavBar
  min-height: 125px
  justify-content: space-between

#topNavBar.dark
  background-color: var(--color-p3)
  border-bottom: 1px solid rgba(220, 214, 255, 0.15)

  ::v-deep
    *
      color: var(--color-t-dark)

    #my-nav-dropdown__BV_toggle_menu_
      background-color: var(--color-b-dark)
      border-color: var(--color-a-borders)

    .dropdown-divider
      border-color: var(--color-a-borders)

    .dropdown-item:hover,
    .dropdown-item:focus
      text-decoration: none
      background-color: var(--color-p2)

#topNavBar
  border-bottom: 3px solid #eaecef
  -webkit-box-sizing: border-box /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box /* Firefox, other Gecko */
  box-sizing: border-box /* Opera/IE 8+ */
  position: sticky
  top: 0
  z-index: 1100
  background-color: #ffffff

  :deep(.nav-link)
    text-decoration: none

  *, .dropdown-item
    color: var(--color-t-dark)

.navbar-toggler
  font-size: 1rem
</style>
