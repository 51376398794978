<template>
  <div class="sandbox-lesson-warning"
    v-if="totalSandboxLabParts > 1"
  >
    <b-alert
      v-if="currentSandboxLabPart > 1"
      show variant="info">
      The lesson above is part {{currentSandboxLabPart}}
      out of {{totalSandboxLabParts}} in a lab series.
      You'll need to complete the parts of the lab leading up to
      this part all in the same sandbox in order for the demo code to work.
      <br />
      <br />
      Sandboxes live for 2 hours.
    </b-alert>
    <b-alert
      show variant="info"
      v-if="currentSandboxLabPart === 1"
    >
      The lesson above is part 1 of a lab series.
      You'll need to complete this and the rest of the parts in order,
      and all in the same sandbox to see the demo code work.
      <br />
      <br />
      Sandboxes live for 2 hours.
    </b-alert>
  </div>
</template>




<script>
export default {
  name: "SandboxLessonWarning",
  props: {
    currentSandboxLabPart: Number,
    totalSandboxLabParts: Number,
  },
};
</script> 

<style scoped>
.sandbox-lesson-warning {
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
}
</style>