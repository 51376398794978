<template>
  <div class="hero">
    <img
      class="courseImage"
      :src="require('@/assets/images/' + lessonData.meta.logo)"
      />
    <div>
      <h1>
        {{ lessonData.meta.title }}
      </h1>
      <div class="badges">
        <b-badge>
          {{ lessonData.meta.tier.text }}
        </b-badge>
        <b-badge>{{ lessonData.meta.lessonsNumber }} Lessons</b-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexHero",
  props: {
    lessonData: Object,
  },
}

</script>

<style scoped lang='sass'>
@import '../assets/styles/e_layout/_media.sass'

.courseImage
  max-width: 180px

.hero
  display: flex
  flex-wrap: wrap
  place-content: center
  place-items: center
  grid-gap: var(--space-sm)
  background-color: var(--color-p3)
  padding: var(--space-md) var(--space-md)
  margin-bottom: var(--space-md)

  @include md
    grid-gap: var(--space-md)
  @include lg
    grid-gap: var(--space-lg)

  h1
    text-align: center
    margin-top: 0

  *
    color: var(--color-a2)

  .badges
    display: flex
    grid-gap: var(--space-xs)
    place-content: center

    @include md
      place-content: flex-start

  .badge
    font-size: unset
    font-weight: unset
    padding: var(--space-2xs) var(--space-xs)
    background-color: var(--color-p2)

</style>