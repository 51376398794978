<template>

<b-container class="course-pagination-nav">
  <b-row align-h="center">
    <b-col 
      cols="4"
      v-if="!isFirstLesson"
    >
      <b-button
        @click="goToPreviousLesson()"
        class="nav-button"
        variant="outline-primary"
      >Previous Lesson
      </b-button>
    </b-col>
    <b-col
      cols="4"
      v-if="!isLastLesson"
    >
      <b-button
        @click="goToNextLesson()"
        class="nav-button"
        variant="outline-primary"
      >Next Lesson
      </b-button>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
export default {
  name: "NextPageLastPageNav",
  props: {
    allLessons: Array,
    currentLesson: Object,
    courseSlug: String,
  },
  computed: {
    isFirstLesson() {
      return this.allLessons.indexOf(this.currentLesson) === 0
    },
    isLastLesson() {
      return this.allLessons.indexOf(this.currentLesson) === this.allLessons.length - 1
    },
  },
  methods: {
    goToNextLesson() {
      const indexOfNextLesson = this.allLessons.indexOf(this.currentLesson) + 1
        const nextLesson = this.allLessons[indexOfNextLesson]
        this.$router.push({
          name: 'lessons',
          params: {
            courseSlug: this.courseSlug,
            lessonSlug: nextLesson.lessonSlug
          }
        })
    },
    goToPreviousLesson() {
      if (this.allLessons.indexOf(this.currentLesson) === 1) {
        // Vue router go to course index
        this.$router.push({
          name: 'index',
          params: { courseSlug: this.courseSlug }
        })
      } else {
        const indexOfPreviousLesson = this.allLessons.indexOf(this.currentLesson) - 1
        const previousLesson = this.allLessons[indexOfPreviousLesson]
        this.$router.push({
          name: 'lessons',
          params: {
            courseSlug: this.courseSlug,
            lessonSlug: previousLesson.lessonSlug
          }
        })
      }
      // eslint-disable-next-line no-console
      console.log(this.courseSlug);
      // console log the previous lesson index
      // eslint-disable-next-line no-console
      console.log(this.allLessons.indexOf(this.currentLesson) - 1);
    },
    logPreviousLesson() {
      // eslint-disable-next-line no-console
      console.log(this.courseSlug);
      // console log the previous lesson index
      // eslint-disable-next-line no-console
      console.log(this.allLessons.indexOf(this.currentLesson) - 1);
    },
  },
}
</script> 

<style scoped>
.course-pagination-nav {
  margin-bottom: 20px;
}
</style>
