import { render, staticRenderFns } from "./NextPageLastPageNav.vue?vue&type=template&id=14723987&scoped=true&"
import script from "./NextPageLastPageNav.vue?vue&type=script&lang=js&"
export * from "./NextPageLastPageNav.vue?vue&type=script&lang=js&"
import style0 from "./NextPageLastPageNav.vue?vue&type=style&index=0&id=14723987&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14723987",
  null
  
)

export default component.exports