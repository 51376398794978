import Vue from "vue";
import Router from "vue-router";

import LessonContent from "../components/LessonContent.vue"
import ProfileView from "../views/ProfileView.vue";
import SandboxView from "../views/SandboxView.vue"
import CoursePage from "../views/CoursePage.vue"
import IndexPage from "../views/IndexPage.vue"

import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/profile'
    },
    {
      path: '/demo',
      redirect: '/courses/demo/'
    },
    {
      path: "/sandbox",
      name: "sandbox",
      component: SandboxView,
      beforeEnter: authGuard
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      beforeEnter: authGuard
    },
    {
      path: "/courses/:courseSlug?",
      name: "index",
      component: IndexPage,
      props: true,
    },
    {
      path: "/courses/:courseSlug?",
      name: "courses",
      component: CoursePage,
      props: true,
      children: [
        {
          name: "lessons",
          path: ':lessonSlug',
          component: LessonContent,
          props: true,
          beforeEnter: authGuard,
        }
      ]
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});

export default router;
