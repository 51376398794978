<template>
  <b-col sm="8" lg="9" xl="9">
    <MuxVideoComponent
      v-if="muxPlaybackId"
      :key="muxPlaybackId"
      :playbackId="muxPlaybackId"
      :videoTitle="currentLesson.lessonTitle"
    />
    <SandboxLoader
      v-if="sandboxType"
      :sandboxType="sandboxType"
      @update-username="onUpdateUsername"
    />
    <div v-if="currentLesson" class="renderedMarkdownBlock">
      <span v-html="renderedMarkdownLesson()"></span>
    </div>
    <SandboxLessonWarning
      v-if="sandboxType"
      :currentSandboxLabPart="currentSandboxLabPart"
      :totalSandboxLabParts="totalSandboxLabParts"
    />
    <NextPageLastPageNav
      :currentLesson="currentLesson"
      :allLessons="lessonList"
      :courseSlug="courseSlug"
    />
  </b-col>
</template>

<script>
import NextPageLastPageNav from "./navigation/NextPageLastPageNav.vue"
import MuxVideoComponent from "./MuxVideoComponent.vue"
import SandboxLoader from "./SandboxLoader.vue"
import SandboxLessonWarning from "./SandboxLessonWarning.vue"
import markdownItHighlight from "markdown-it-highlightjs"

import markdownItAnchor from "markdown-it-anchor"
import markdownItMeta from "markdown-it-meta"

var md = require("markdown-it")()
  .use(require("markdown-it-external-links"), {
    externalClassName: null,
    externalRel: "noopener noreferrer",
    externalTarget: "_blank",
  })
  .use(require("markdown-it-task-lists"), {
    label: true,
  })
  .use(require("markdown-it-abbr"))
  .use(require("markdown-it-sup"))
  .use(require("markdown-it-sub"))
  .use(require("markdown-it-mark"))
  .use(require("markdown-it-ins"))
  .use(markdownItAnchor, {
    permalink: false,
    permalinkSymbol: "",
    permalinkBefore: true,
    permalinkSpace: true,
  })

md.use(markdownItHighlight, { auto: false })
md.use(markdownItMeta)

export default {
  name: "LessonContent",
  components: {
    SandboxLoader,
    SandboxLessonWarning,
    MuxVideoComponent,
    NextPageLastPageNav,
  },
  data() {
    return {
      username: "",
      muxPlaybackId: "",
      sandboxType: "",
      currentSandboxLabPart: 0,
      totalSandboxLabParts: 0,
    }
  },
  props: {
    lessonSlug: String,
    lessonList: Array,
    courseSlug: String
  },
  methods: {
    onUpdateUsername(username) {
      this.username = username
    },
    renderedMarkdownLesson() {
      if (this.username !== "") {
        const currentContent = this.currentLesson.content
        const updatedContent = md.render(
          currentContent
            .replace(/RESERVED_RESOURCE_NAME_VARIABLE/g, this.username)
        )
        this.muxPlaybackId = md.meta.muxPlaybackId
        this.sandboxType = md.meta.sandboxType
        this.currentSandboxLabPart = md.meta.currentSandboxLabPart
        this.totalSandboxLabParts = md.meta.totalSandboxLabParts
        return updatedContent
      }
      const renderedMarkdown = md.render(this.currentLesson.content)
      this.muxPlaybackId = md.meta.muxPlaybackId
      this.sandboxType = md.meta.sandboxType
      this.currentSandboxLabPart = md.meta.currentSandboxLabPart
      this.totalSandboxLabParts = md.meta.totalSandboxLabParts
      return renderedMarkdown
    },
  },
  computed: {
    currentLesson() {
      return this.lessonList.find((obj) => obj.lessonSlug === this.lessonSlug)
    },
  },
}
</script>

<style>
img {
  margin: 0 auto;
}
</style>