const lengthOfExtension = '.md'.length
const lessonTitleFromFirstPoundRegex = /# [0-9a-zA-Z'?!`: ]*/gm;

export function enrichManifest (courseManifest) {
  var manifest = []
  const courseLessons = courseManifest.lessons
  const courseUrl = courseManifest.courseUrl
  courseLessons.forEach(filename => {
    import(`@/assets/code/${courseUrl}/${filename}`).then(lessonModule => {
      const lessonModuleString = lessonModule.default
      const lessonFileNameWithoutExtension = filename.slice(0, -lengthOfExtension)
      let lessonOrder
      let lessonSlug
      if (lessonFileNameWithoutExtension === 'index'){
        lessonOrder = 0
        lessonSlug = 'index'
      } else {
        // Splits 01-introduction into
        // ['01', 'introduction']
        lessonOrder = parseInt(lessonFileNameWithoutExtension.split('-')[0])
        lessonSlug = lessonFileNameWithoutExtension.slice(3)
      }
      const lessonTitle = lessonModuleString.match(lessonTitleFromFirstPoundRegex)[0].slice(2)
      manifest.push({
        lessonTitle: lessonTitle,
        lessonSlug: lessonSlug,
        content: lessonModuleString,
        lessonOrder: lessonOrder,
      })
    })
  })
  return manifest
}