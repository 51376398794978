<template>
  <div class="get-sandbox-container">
    <b-button
      block
      variant="primary"
      @click="createSandbox()"
      v-if="!sandboxDetails.loaded && !errorMessage"
      :disabled="isCurrentlyCreatingSandbox"
      class="get-sandbox-btn"
    >
      <b-spinner small v-if="isCurrentlyCreatingSandbox"></b-spinner>
      {{ (isCurrentlyCreatingSandbox) ? "Creating Sandbox..." : "Get Sandbox Details" }}
    </b-button>
  
    <b-card
      v-if="sandboxDetails.loaded || errorMessage || longLoadMessage"
      class="sandbox-details"
      header="Your Sandbox Details:"
      header-tag="header"
      :footer="sandboxDetails.title"
      footer-tag="footer"
      :title="sandboxDetails.title"
    >
      <b-card-text v-if="errorMessage">
        <b>
          {{ errorMessage }}
        </b>
      </b-card-text>
      <b-card-text v-if="longLoadMessage">
        <b>
          {{ longLoadMessage }}
        </b>
      </b-card-text>
      <b-spinner small v-if="isCurrentlyCreatingSandbox"></b-spinner>
      <b-card-text
        v-if="sandboxDetails.username"
      >
        Sandbox Resource Names: {{ sandboxDetails.username }}
      </b-card-text>
      <b-card-text
        v-if="sandboxDetails.sandbox_url"
      >
        Sandbox URL: <br/>{{ sandboxDetails.sandbox_url }}
      </b-card-text>
      <b-card-text
        v-if="sandboxDetails.sandbox_url"
      >
        Note: This sandbox will be eventually be deleted.
      </b-card-text>
      <b-card-text
        v-if="sandboxDetails.sandbox_url"
      >
        {{ timeRemaining }}
      </b-card-text>
      <b-button
        v-if="sandboxDetails.sandbox_url"
        class="sandbox-open-btn"
        @click="openUrlInNewTab(sandboxDetails.sandbox_url)"
        variant="primary"
        >Open Sandbox</b-button
      >
      <b-button
        v-if="errorMessage || sandboxDetails.sandbox_url"
        class="sandbox-delete-btn"
        @click="deleteSandbox()"
        variant="danger"
        :disabled="isCurrentlyDeletingSandbox"
      > 
      <b-spinner small v-if="isCurrentlyDeletingSandbox"></b-spinner>
      {{ (isCurrentlyDeletingSandbox) ? "Deleting Sandbox..." : "Delete Sandbox"}}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import axiosRetry from "axios-retry";

const moonriseApiBaseUrl = process.env.VUE_APP_API_BASE_ENDPOINT;
const axiosNoRetryClient = axios.create({ baseURL: moonriseApiBaseUrl });

const retryDelay = (retryCount = 0) => {
  if (retryCount == 0 || retryCount == 1) {
    // Initial wait time 5 seconds (sometimes it's ready real fast)
    return 1000 * 5;
  } else {
    // Otherwise, just wait one minute at a time
    return 1000 * 60;
  }
}

const retryCondition = (error) => {
  return error.response.status == 404;
}

function createAxiosRetryClient(retryHandler) {
  const axiosClient = axios.create({ baseURL: moonriseApiBaseUrl });
  axiosRetry(axiosClient, {
    retries: 10,
    retryDelay: retryDelay,
    retryCondition: retryCondition,
    onRetry: retryHandler
  });
  return axiosClient;
}


export default {
  name: "SandboxLoader",
  props: {
    sandboxType: String,
  },
  data() {
    return {
      sandboxDetails: {
        assignment_time: "",
        sandbox_status: "",
        loaded: false,
        sandbox_url: "",
        sandbox_type: "",
        username: "",
      },
      errorMessage: "",
      longLoadMessage: "",
      isCurrentlyCreatingSandbox: false,
      isCurrentlyDeletingSandbox: false,
      info: null,
      timeRemaining: "",
      sandboxWasDeleted: false,
    };
  },
  mounted: function () {
    setInterval(() => {
      this.timeRemaining = this.calculateTimeRemaining();
    }, 1000);
    setInterval(() => {
      // Automatically create a sandbox if one isn't loaded
      // But only if we're not currently creating one
      // And only if we haven't already deleted the sandbox
      if (
        !this.sandboxDetails.loaded && 
        !this.isCurrentlyCreatingSandbox &&
        !this.sandboxWasDeleted
      ) {
        this.createSandbox();
      }
    }, 2000);
  },
  methods: {
    updateUsername(username) {
      this.$emit("update-username", username);
    },
    getLocalExpiryTime() {
      var localExpiryTime = moment
        .utc(this.sandboxDetails.assignment_time, "YYYY-MM-DD HH:mm:ss.SSSSSS")
        .add(2, "hours")
        .local()
        .format("MMMM Do YYYY, h:mm:ss a");
      return localExpiryTime;
    },
    calculateTimeRemaining() {
      if (this.sandboxDetails.assignment_time != "") {
        var expiryTime = moment
          .utc(this.sandboxDetails.assignment_time)
          .add(2, "hours");
        var timeRemaining = expiryTime.subtract(moment());
        var timeRemainingExplanation =
          "You have " +
          timeRemaining.get("hours") +
          " hours, " +
          timeRemaining.get("minutes") +
          " minutes, and " +
          timeRemaining.get("seconds") +
          " seconds remaining.";
        if (timeRemaining <= 0) {
          return "The sandbox has expired. In a few minutes you can refresh the page and create a new one.";
        } else {
          return timeRemainingExplanation; 
        }
      } else {
        return "The countdown will begin shortly...";
      }
    },
    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },
    async deleteSandbox() {
      this.isCurrentlyDeletingSandbox = true;
      const token = await this.$auth.getTokenSilently();
      await axiosNoRetryClient.delete("/sandbox", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.sandbox_url = "";
      this.isCurrentlyDeletingSandbox = false;
      this.errorMessage = "";
      this.sandboxDetails.loaded = false;
      this.sandboxWasDeleted = true;
    },
    async getSandbox() {
      const token = await this.$auth.getTokenSilently();
      this.sandboxDetails.sandbox_url = "";
      this.sandboxDetails.sandbox_type = "";
      const axiosRetryClient = createAxiosRetryClient((retryCount, error) => {
        if (retryCount === 1) {
          this.longLoadMessage = "The sandbox is taking longer than usually to load. Sorry about that!";
        }
        if (retryCount === 2) {
          this.longLoadMessage = "Looks like we've got higher traffic than normal and the sandboxes we keep ready are all used up - but we're making a new one just for you! Sorry about the wait!";
        }
        if (retryCount === 3) {
          this.longLoadMessage = "Don't worry! We're still working on it. We've created the cloud resources we needed for you and now we're making sure the sandbox is ready to go!";
          this.sandboxDetails.username = error.response.data.data.username
          this.updateUsername(this.sandboxDetails.username);
        }
        if (retryCount === 4) {
          this.longLoadMessage = "We're almost there! If you're still seeing this after another minute then refresh the page just to be safe!";
        }
      });
      const getSandboxResponse = await axiosRetryClient.get("/sandbox", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sandboxData = getSandboxResponse.data.data
      if (getSandboxResponse.status == 200) {
        this.sandboxDetails.sandbox_url = sandboxData.azure_container_url;
        this.sandboxDetails.sandbox_type = sandboxData.sandbox_type;
        if (this.sandboxType !== this.sandboxDetails.sandbox_type) {
          this.errorMessage = 
            "You already have a sandbox running of a different type. "
            + "Please review the details below, and delete it before you continue.";
        }
        this.sandboxDetails.username = sandboxData.username;
        this.updateUsername(this.sandboxDetails.username);
        this.sandboxDetails.assignment_time = sandboxData.assignment_time;
        this.longLoadMessage = "";
      }
    },
    async createSandbox() {
      this.isCurrentlyCreatingSandbox = true;
      const token = await this.$auth.getTokenSilently();
      const createSandboxResponse = await axiosNoRetryClient.post(
        "/sandbox",
        {
          sandbox_type: this.sandboxType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return status < 500;
          },
        },
      );
      if (createSandboxResponse.status == 404) {
        if (createSandboxResponse.data.code == 'assigned_sandbox_already_exists') {
          this.errorMessage = (
            "You already have a sandbox created or warming up right now."
          );
          this.sandbox_url = "";
          await this.getSandbox();
          this.sandboxDetails.loaded = true;
          this.isCurrentlyCreatingSandbox = false;
        }
      }
      if (createSandboxResponse.status == 200 || createSandboxResponse.status == 202) {
        // These mean the request kicked off fine. 
        // 202 means that it's warming
        // 200 means it's created/assigned
        await this.getSandbox();
        this.sandboxDetails.loaded = true;
        this.isCurrentlyCreatingSandbox = false;
      }
    }
  },
};
</script> 
