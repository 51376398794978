<template>
  <b-col id="mobileNavContainer" class="d-block d-sm-none">
    <b-navbar id="mobileNav" toggleable="sm">
      <b-navbar-toggle id="mobileToc" target="tocCollapse">
        <template #default="{ expanded }">
          Table of Contents
          <b-icon
            id="mobileNavChevronUp"
            v-if="expanded"
            icon="chevron-bar-up"
          ></b-icon>
          <b-icon
            id="mobileNavChevronDown"
            v-else
            icon="chevron-bar-down"
          ></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="tocCollapse" is-nav>
        <ItemsList :allLessons="allLessons" :courseSlug="courseSlug" />
      </b-collapse>
    </b-navbar>
  </b-col>
</template>

<script>
import ItemsList from "./ItemsList.vue"

export default {
  name: "MobileSidebar",
  components: {
    ItemsList,
  },
  props: {
    allLessons: Array,
    courseSlug: String,
  },
}
</script> 

<style scoped>
#mobileNavContainer {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  overflow-y: scroll;
  position: sticky;
  z-index: 1000;
  background: none;
  margin-top: 30px;
}

#mobileNav {
  padding: 0;
}

#mobileToc {
  background: #00bba3;
  margin: 0 auto;
  width: 100%;
  padding: 0.75rem 0.75rem;
  color: #23142d;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 0;
}

#mobileNavChevronUp {
  vertical-align: -0.2em;
}

#mobileNavChevronDown {
  vertical-align: -0.25em;
}

::v-deep .nav-link {
  text-decoration: none;
}

::v-deep .nav-link:hover {
  text-decoration: underline;
}
</style>

