<template>
  <b-row>
    <b-alert
      id="wrongPlanAlert"
      v-model="showAlert"
      class="position-fixed fixed-top m-0 rounded-0"
      variant="info"
      dismissible
    >
      Oops! Looks like you need to be on the "{{ tier }}" plan to access that
      content! You can sign up for that plan below by clicking the "Upgrade Now"
      button.
    </b-alert>
  </b-row>
</template>

<script>
export default {
  name: "WrongPlanAlert",
  data() {
    return {
      showAlert: true,
    };
  },
  props: {
    tier: String,
  },
};
</script>

<style scoped>
#wrongPlanAlert {
  z-index: 2000;
  width: 100vw;
  font-size: 1.5rem;
}
</style>
