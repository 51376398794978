<template>
  <div>
    <!-- centered b-col -->
    <b-col class="sandbox-selector">
      <b-form-group
        label="Sandbox Type"
        label-for="sandbox-type-select"
      >
        <b-form-select
          id="sandbox-type-select"
          v-model="sandboxType"
          :options="sandboxTypes"
          size="sm"
        />
      </b-form-group>
    </b-col>
    <SandboxLoader
      v-if="sandboxType"
      :sandboxType="sandboxType"
      @update-username="onUpdateUsername"
    />
  </div>
</template>

<script>
import SandboxLoader from "../components/SandboxLoader.vue"

export default {
  name: "SandboxView",
  components: {
    SandboxLoader,
  },
  methods: {
    onUpdateUsername(username) {
      this.username = username
    },
  },
  data() {
    return {
      username: "",
      sandboxType: "",
      sandboxTypes: [
        { value: null, text: "Select a sandbox type" },
        { value: "theia-python-node-aws", text: "Theia" },
        { value: "openvscode-basic", text: "VS Code" },
      ],
    }
  },
}
</script>

<style scoped>
.sandbox-selector {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
</style>