import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import { Auth0Plugin } from "./auth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'

import './assets/styles/app.scss'
import './assets/styles/index.sass'

// Syntax highlighting with Vue Highlight JS
import 'highlight.js/styles/stackoverflow-light.css'
import 'highlight.js/lib/common';
import hljsVuePlugin from "@highlightjs/vue-plugin";

Vue.use(hljsVuePlugin)



Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.prototype.moment = moment

const domain = process.env.VUE_APP_AUTH0_DOMAIN
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
const audience = process.env.VUE_APP_AUTH0_AUDIENCE

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

library.add(faLink, faUser, faPowerOff, faBookmark);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
