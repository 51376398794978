<template>
  <nav class="sidebar">
    <h3 class="sidebar-title">Table of Contents</h3>
    <router-link
      v-for="(l, i) in orderedLessonsWithoutIndex"
      :key="i"
      :to="{
        name: 'lessons',
        params: { courseSlug: courseSlug, lessonSlug: l.lessonSlug },
      }"
      class="link"
      >
      {{orderedLessonsWithoutIndex.indexOf(l) + 1}}. {{ l.lessonTitle }}
    </router-link>
  </nav>
</template>


<script>
export default {
  name: "IndexPageSidebar",
  props: {
    orderedLessons: Array,
    courseSlug: String,
  },
  computed: {
    orderedLessonsWithoutIndex() {
      return this.orderedLessons.filter((l) => l.lessonSlug !== "index");
    },
  },
}

</script>


<style scoped lang="sass">
@import '../assets/styles/e_layout/_media.sass'

.sidebar
  display: flex
  flex-direction: column
  margin-top: var(--space-sm)
  margin-bottom: var(--space-sm)

  .sidebar-title
    margin-bottom: var(--space-xs)
    font-size: var(--text-md)

  @include lg
    padding-right: var(--space-sm)
    border-right: 1px solid var(--color-a2)

  .link
    color: var(--color-t-alt)
    text-decoration: none
    font-stretch: semi-condensed
    margin-bottom: var(--space-2xs)
  
  .link:hover
    color: var(--color-p2)
</style>