<template>
  <b-nav vertical>
    <template v-for="lesson in orderedLessons">
      <CourseListItem
        v-if="isCurrentlyActiveItem(lesson.lessonSlug)"
        class="activeItem"
        :lesson="lesson"
        :courseSlug="courseSlug"
        v-bind:key="lesson.lessonSlug"
      />
      <CourseListItem
        v-if="!isCurrentlyActiveItem(lesson.lessonSlug)"
        :lesson="lesson"
        :courseSlug="courseSlug"
        v-bind:key="lesson.lessonSlug"
      />
    </template>
  </b-nav>
</template>

<script>
import CourseListItem from "./CourseListItem.vue";

export default {
  name: "ItemsList",
  methods: {
    isCurrentlyActiveItem(lessonSlug) {
      // Catches when the index page is used which is in the form:
      // DOMAIN/courses/python-fundamentals/
      const isTopLevelIndexCoursePathWithSlash =
        lessonSlug === "index" && this.$route.path.split("/").length === 3;
      const isTopLevelIndexCoursePathWithoutSlash =
        lessonSlug === "index" &&
        this.$route.path.split("/").slice(-1)[0] === "";
      if (
        isTopLevelIndexCoursePathWithoutSlash ||
        isTopLevelIndexCoursePathWithSlash
      ) {
        return true;
      }
      return lessonSlug === this.$route.path.split("/").slice(-1)[0];
    },
  },
  components: {
    CourseListItem,
  },
  props: {
    allLessons: Array,
    courseSlug: String,
  },
  computed: {
    orderedLessons() {
      return [...this.allLessons].sort((a, b) => a.lessonOrder - b.lessonOrder);
    },
  },
};
</script>
