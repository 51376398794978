<template>
  <div id="app" class="d-flex flex-column">
    <NavBar />
    <AuthError />
    <router-view style="display: contents" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import AuthError from "./components/AuthError";

export default {
  components: {
    NavBar,
    AuthError,
  },
};
</script>

<style lang="sass" scoped>
#app
  margin-bottom: var(--space-md)
</style>