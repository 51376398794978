<template>
  <b-container fluid>
    <b-row class="lesson-row">
      <MobileSidebar
        v-if="lessonList"
        :allLessons="lessonList"
        :courseSlug="courseSlug"
      />
      <RegularSidebar
        v-if="lessonList"
        :allLessons="lessonList"
        :courseSlug="courseSlug"
      />
      <router-view v-if="lessonList" :lessonList="lessonList" />
    </b-row>
  </b-container>
</template>

<script>
import MobileSidebar from "../components/sidebar/MobileSidebar.vue";
import RegularSidebar from "../components/sidebar/RegularSidebar.vue";

import { enrichManifest } from "./utils";

export default {
  name: "CoursePage",
  components: {
    MobileSidebar,
    RegularSidebar,
  },
  data: function () {
    return {
      lessonList: [],
    };
  },
  props: {
    courseSlug: String,
  },
  mounted() {
    this.lessonList = this.loadingLessonList;
  },
  created() {},
  computed: {
    loadingLessonList() {
      const manifest = enrichManifest(
        require(`../assets/code/${this.courseSlug}/manifest.json`)
      );
      return manifest;
    },
  },
};
</script> 
