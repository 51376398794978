<template>
  <b-row>
    <b-alert
      id="checkoutAlert"
      v-model="showAlert"
      class="position-fixed fixed-top m-0 rounded-0"
      variant="info"
      dismissible
    >
      Now that you're signed in, you can manage your plan below!
    </b-alert>
  </b-row>
</template>

<script>
export default {
  name: "CheckoutAlert",
  data() {
    return {
      showAlert: true,
    };
  },
  props: {
    tier: String,
  },
};
</script>

<style scoped>
#checkoutAlert {
  z-index: 2000;
  width: 100vw;
  font-size: 1.5rem;
}
</style>
