import courseTiers from "./courseTiers.js"
import jwt_decode from "jwt-decode";

import { getInstance } from "./authWrapper";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = async () => {
    if (authService.isAuthenticated) {
      const currentPath = to.path
      const standardTierCourseSlugs = courseTiers.standard
      const currentPathRequiresStandardTier = standardTierCourseSlugs.some(courseSlug => 
        {
        if (currentPath.includes(courseSlug)) {
          return true;
        }
        return false;
      });
      // If we're not looking at a standard tier course then go ahead
      if (!currentPathRequiresStandardTier) {
        return next()
      }

      // eslint-disable-next-line no-console
      console.log(to.path)
      // If we're looking at a standard tier course then...
      if (currentPathRequiresStandardTier) {
        let token
        try {
          token = await authService.getTokenSilently()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("ERROR IN AUTHGUARD")
        }
        const decoded = jwt_decode(token)
        const permissions = decoded["permissions"]
        const hasStandardTier = permissions.includes("access:standard")
        if (!hasStandardTier) {
          return next({ name: 'profile', query: { tierFailure: 'standard' }})
        }
        return next()
      }
      
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.loading) {
    return guardAction();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return guardAction();
    }
  });
};
